import { css } from "@emotion/react";
import { navigate } from "gatsby";
import Button from "atoms/Button";
import Layout from "src/components/templates/Layout";
import Sidebar from "src/components/templates/docs/Sidebar";

const style = Object.freeze({
  container: css`
    padding: 128px;
    height: 100vh;
  `,
  title: css`
    font-size: 2rem;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 128px;
  `,
  top: css`
    width: 280px;
    margin: auto;
  `,
});
// markup
const NotFoundPage = () => {
  return (
    <Layout sidebar={<Sidebar />}>
      <div css={style.container}>
        <h1 css={style.title}>404 Not Found</h1>
        <p css={style.top}>
          <Button
            type="secondary"
            label="トップに戻る"
            onClick={() => navigate("/")}
          />
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
